<template>
  <div class="d-flex">
    <div v-for="n in colors"
        :key="n">
      <color-picker-button :selected.sync="colorValue" :colorValue="n"/>
    </div>
</div>
</template>

<script>

export default {
  components: {
    ColorPickerButton: () => import('@/components/color-picker-button')
  },
  computed: {
    colors () {
      return [
        'red',
        'pink',
        'purple',
        'deep-purple',
        'indigo',
        'blue',
        'light-blue',
        'cyan',
        'teal',
        'green',
        'light-green',
        'lime',
        'yellow',
        'amber',
        'orange',
        'deep-orange',
        'brown',
        'blue-grey',
        'grey']
    },
    colorValue: {
      get () {
        return this.selected
      },
      set (value) {
        this.$emit('update:selected', value)
      }
    }

  },
  props: {
    selected: {
      type: String,
      default: 'red'
    }
  }
}
</script>

<style lang="stylus">

</style>
